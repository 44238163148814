<template>
  <div class="mendian-page">
    <div class="mendian-page-header">
      <div class="main flex alcenter space">
        <div class="flex alcenter">
          <router-link to="/mendian">
            <img
              v-if="siteInfo != null"
              :src="siteInfo.logo_white_account_manage"
              style="height: 24px; width: 148px"
            />
          </router-link>
          <span class="title">商户管理中心</span>
        </div>
        <div class="flex alcenter">
          <span class="ft14 cl-theme mr10">欢迎您，{{ account.username }}</span>
          <span class="log-out" @click="loginOut()">退出</span>
        </div>
      </div>
    </div>

    <div class="mt40" style="flex: auto">
      <div class="mendian-page-main">
        <router-view></router-view>
      </div>
    </div>

    <div class="mendian-footer text-center pd24_20 ft14 cl-info">
      ©2012-{{ getNowYear }} {{ siteInfo ? siteInfo.domain : "" }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account: {
        mobile: "",
        user_name: "",
        face: "",
      },
      siteInfo: null,
    };
  },
  computed: {
    getNowYear() {
      let date = new Date();
      return date.getFullYear();
    },
  },
  created() {
    this.showTime();
    this.getSiteInfo();
    this.getLayoutData();
  },
  methods: {
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    showTime() {
      var now = new Date();
      var hour = now.getHours();
      var t = "";
      if (hour < 6) {
        t = "凌晨好";
      } else if (hour < 9) {
        t = "早上好";
      } else if (hour < 12) {
        t = "上午好";
      } else if (hour < 14) {
        t = "中午好";
      } else if (hour < 17) {
        t = "下午好";
      } else if (hour < 19) {
        t = "傍晚好";
      } else if (hour < 22) {
        t = "晚上好";
      } else {
        t = "夜里好";
      }
      this.showT = t;
    },

    getLayoutData() {
      this.$http
        .api("platform/account/layout")
        .then((res) => {
          this.account = res.account;
        })
        .catch((res) => {
          console.log(res);
        });
    },

    loginOut() {
      localStorage.removeItem("account-token");
      this.$router.push("/");
    },
  },
};
</script>

<style>
.mendian-footer {
  flex: 0 0 auto;
}
.mendian-list {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  grid-template-rows: repeat(3, 100px);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
.mendian-item {
  width: 380px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  border-left: 8px solid #ebedf5;
  padding: 24px 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.mendian-item .tag {
  width: 52px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #4772ff;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: #4772ff;
}
.mendian-item:hover {
  transform: translateY(-10px);
}
.mendian-item.add {
  border: 1px dashed #4772ff;
}
.mendian-page {
  min-width: 1200px;
  min-height: 100vh;
  display: flex;
  flex: auto;
  flex-direction: column;
}
.mendian-page-header {
  width: 100%;
  height: 60px;
  background: #ffffff;
  flex: 0 0 auto;
}
.mendian-page-header .main {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
}
.mendian-page-header .log-out {
  width: 44px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #4772ff;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  color: #4772ff;
  cursor: pointer;
}
.mendian-page-header .title {
  font-size: 20px;
  font-weight: 600;
  color: #4772ff;
  padding-left: 12px;
  border-left: 1px solid #ffffff;
  margin-left: 12px;
  height: 20px;
  line-height: 20px;
}
.mendian-page-main {
  width: 1200px;
  margin: 0 auto;
}
</style>